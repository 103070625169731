import React, { useEffect, useRef } from "react";

// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { ExcelRenderer } from "react-excel-renderer";
import { fetchReps } from "../../../Redux/usersReduce";
import { fetchLine } from "../../../Redux/lineReduce";
import { fetchBrick } from "../../../Redux/brickReduce";
import { fetchCycle } from "../../../Redux/cycleReduce";
import { fetchAnneeIms } from "../../../Redux/anneeReduce";
import {
  cheeckParams,
  saveFile,
  verifData,
  dataAdded,
} from "../../../Redux/dataReduce";
import { fetchPersonnel } from "../../../Redux/personnelReduce";
import { fetchSpecialite } from "../../../Redux/specialiteReduce";

function InsertPltData() {
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const dispatch = useDispatch();
  const location = useParams();
  const navigate = useHistory();
  const [fileName, setFileName] = React.useState("");
  const [entities, setEntities] = React.useState([]);
  const [uploadFile, setUploadFile] = React.useState("");
  const [testBtn, setTestBtn] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const saveTableCalled = useRef(false);

  const [contactOptions, setContactOptions] = React.useState([
    {
      value: "",
      label: "Contact",
      isDisabled: true,
    },
  ]);
  const [contactSelect, setContactSelect] = React.useState({
    value: 0,
    label: "Contact",
  });

  const [repsOptions, setRepsOptions] = React.useState([
    {
      value: "",
      label: "Rep",
      isDisabled: true,
    },
  ]);
  const [repSelect, setRepSelect] = React.useState({
    value: 0,
    label: "Rep",
  });

  const [linesOptions, setLinesOptions] = React.useState([
    {
      value: "",
      label: "Line",
      isDisabled: true,
    },
  ]);
  const [lineSelect, setLineSelect] = React.useState({
    value: 0,
    label: "Line",
  });

  const [bricksOptions, setBricksOptions] = React.useState([
    {
      value: "",
      label: "Brick",
      isDisabled: true,
    },
  ]);
  const [brickSelect, setBrickSelect] = React.useState({
    value: 0,
    label: "Brick",
  });

  const [specialiteOptions, setSpecialiteOptions] = React.useState([
    {
      value: "",
      label: "Spécialité",
      isDisabled: true,
    },
  ]);
  const [specialitSelect, setSpecialitSelect] = React.useState({
    value: 0,
    label: "Spécialité",
  });

  const [cyclesOptions, setCyclesOptions] = React.useState([
    {
      value: "",
      label: "Cycle",
      isDisabled: true,
    },
  ]);
  const [cycleSelect, setCycleSelect] = React.useState({
    value: 0,
    label: "Cycle",
  });

  const [cycle, setCycle] = React.useState({
    value: null,
    label: "Cycle",
  });

  const [anneeOptions, setAnneeOptions] = React.useState([
    {
      value: "",
      label: "Année",
      isDisabled: true,
    },
  ]);
  const [anneeSelect, setAnneeSelect] = React.useState({
    value: "",
    label: "Année",
  });

  const [arrayLines, setArrayLines] = React.useState([]);
  const [arrayCycles, setArrayCycles] = React.useState([]);
  const [arrayReps, setArrayReps] = React.useState([]);
  const [arraySp, setArraySp] = React.useState([]);
  const [arrayPers, setArrayPers] = React.useState([]);
  const [arrayBrick, setArrayBrick] = React.useState([]);

  const [arrayIdLines, setArrayIdLines] = React.useState([]);
  const [arrayIdCycles, setArrayIdCycles] = React.useState([]);
  const [arrayIdReps, setArrayIdReps] = React.useState([]);
  const [arrayIdSp, setArrayIdSp] = React.useState([]);
  const [arrayIdPers, setArrayIdPers] = React.useState([]);
  const [arrayIdBrick, setArrayIdBrick] = React.useState([]);

  const fileHandler = (e) => {
    let fileObj = e.target.files[0];
    if (
      fileObj.name.slice(-5) === ".xlsx" ||
      fileObj.name.slice(-4) === ".xls" ||
      fileObj.name.slice(-4) === ".csv"
    ) {
      setUploadFile(fileObj);
      var str = fileObj.name.replace(/[^a-zA-Z0-9,;\-.!? ]/g, "");
      setFileName(str);
    } else {
      notify(2, "Votre fichier doit être de type CSV !");
    }
  };

  const showTable = React.useCallback(
    async (annee, cycle, idCycle) => {
      setDisabled(true);
      try {
        if (fileName !== "") {
          ExcelRenderer(uploadFile, (err, resp) => {
            if (err) {
              setDisabled(false);
              console.log("error", err);
            } else {
              verifEntities(resp.rows, annee, cycle, idCycle);
            }
          });
        } else {
          setDisabled(false);
          notify(2, "Vérifiez vos données");
        }
      } catch (error) {
        setDisabled(false);
        console.error(error);
      }
    },
    [fileName, uploadFile]
  );

  const verifEntities = (array, annee, cycle, idCycle) => {
    document.getElementById("loaderTable").classList.remove("hidden");
    let newArray = array.filter((arr) => arr.length > 0);
    var dataArray = [];
    var testHeader = null;

    const elementsCheck = [
      "Line",
      "Territoire",
      "Matricule",
      "Rep",
      "Cycle",
      "Semaine",
      "Jour",
      "TypeActivite",
      "Contact",
      "Spécialité",
      "Brick",
      "Commune",
      "Segment",
      "VilleBase",
      "Fréquence",
      "Distance",
      "Code OneKey",
      "BaseDéplacement",
      "TypePoste",
    ];

    const newArr = newArray[0].map((element) => element.trim());
    testHeader = elementsCheck.every((element) => newArr.includes(element));

    if (testHeader) {
      newArray.slice(1).forEach((element, index) => {
        var objPP = {};
        if (element.length > 0) {
          objPP = {
            line: element[0],
            territoire: element[1],
            matricule: element[2],
            rep: element[3],
            cycle: element[4],
            semaine: element[5],
            jour: element[6],
            typeActivite: element[7],
            contact: element[8],
            specialite: element[9],
            brick: element[10],
            commune: element[11],
            segment: element[12],
            villeBase: element[13],
            frequence: element[14],
            distance: element[15],
            codeOneKey: element[16],
            baseDeplacement: element[17],
            typePoste: element[18],
          };
        }
        dataArray.push(objPP);
      });
      const checkArray = dataArray.filter(
        (row) => row.cycle.toUpperCase() === cycle.toUpperCase()
      );
      dispatch(verifData({ annee: annee, idCycle: idCycle, type: 5 })).then(
        (res) => {
          if (res.payload === true) {
            if (checkArray.length > 0) {
              dispatch(cheeckParams({ data: checkArray })).then((rows) => {
                document.getElementById("loaderTable").classList.add("hidden");
                setEntities(rows.payload);
              });
            } else {
              setDisabled(false);
              document.getElementById("loaderTable").classList.add("hidden");
              notify(2, "Vérifier votre fichier !");
            }
          } else {
            setDisabled(false);
            document.getElementById("loaderTable").classList.add("hidden");
            notify(2, res.payload.msg);
          }
        }
      );
    } else {
      setDisabled(false);
      document.getElementById("loaderTable").classList.add("hidden");
      notify(2, "Vérifier votre fichier !");
    }
  };

  const saveTable = React.useCallback(
    async (entities, uploadFile, idCycle, anneeVal) => {
      if (saveTableCalled.current) return;
      saveTableCalled.current = true;
      var verif = true;

      //keys cycles
      const keys1 = entities
        .filter((element) => element.id_cycle == "")
        .map((element) => element.index);
      const keysCycle = keys1.join("-");

      //Keys Bricks
      const keys2 = entities
        .filter((element) => element.id_brick == "")
        .map((element) => element.index);
      const keysBrick = keys2.join("-");

      // Keys Rep
      const keys3 = entities
        .filter((element) => element.id_rep == "")
        .map((element) => element.index);
      const keysRep = keys3.join("-");

      // Keys Line
      const keys4 = entities
        .filter((element) => element.id_line == "")
        .map((element) => element.index);
      const keysLine = keys4.join("-");

      //Keys Contact
      const keys5 = entities
        .filter((element) => element.id_contact == "")
        .map((element) => element.index);
      const keysCont = keys5.join("-");

      // Keys Specialite
      const keys6 = entities
        .filter((element) => element.id_specialite == "")
        .map((element) => element.index);
      const keysSp = keys6.join("-");

      const checks = [
        {
          keys: keys1,
          message: "Veuillez choisir les cycles des lignes " + keysCycle,
        },
        {
          keys: keys2,
          message: "Veuillez choisir les bricks des lignes " + keysBrick,
        },
        {
          keys: keys3,
          message: "Veuillez choisir les reps des lignes " + keysRep,
        },
        {
          keys: keys4,
          message: "Veuillez choisir les lines des lignes " + keysLine,
        },
        {
          keys: keys5,
          message: "Veuillez choisir les contacts des lignes " + keysCont,
        },
        {
          keys: keys6,
          message: "Veuillez choisir les spécialités des lignes " + keysSp,
        },
      ];
      checks.forEach((check) => {
        if (check.keys.length > 0) {
          verif = false;
          notify(2, check.message);
        }
      });

      if (
        uploadFile &&
        entities.length > 0 &&
        verif &&
        anneeVal !== "" &&
        idCycle !== null
      ) {
        document.getElementById("loaderTable").classList.remove("hidden");
        const dataArray = new FormData();
        dataArray.append("file", uploadFile);
        dataArray.append("name", uploadFile.name);
        dispatch(saveFile(dataArray)).then((e) => {
          setTestBtn(true);
          var filename = e.payload.filename;
          dispatch(
            dataAdded({
              file: filename,
              namefile: uploadFile.name,
              lignes: entities,
              type: 5,
              id_cycle: idCycle,
              annee: anneeVal,
            })
          ).then((e) => {
            if (e.payload) {
              document.getElementById("loaderTable").classList.add("hidden");
              notify(1, "Insertion avec succés !");
              setTimeout(() => {
                listData();
              }, 1300);
            } else {
              document.getElementById("loaderTable").classList.add("hidden");
              saveTableCalled.current = false;
              setTestBtn(false);
              notify(2, "Problème de connexion !");
            }
          });
        });
      } else {
        document.getElementById("loaderTable").classList.add("hidden");
        saveTableCalled.current = false;
        notify(2, "Vérifier vos données !");
      }
    },
    []
  );

  async function getContacts() {
    let object = {};
    let arrayOption = [];
    let contacts = await dispatch(fetchPersonnel());
    let entities = contacts.payload;
    arrayOption.push({ value: 0, label: "Contact", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nomPrenom });
      object[e.id] = { value: e.id, label: e.nomPrenom };
    });
    setContactSelect(object);
    setContactOptions(arrayOption);
    setArrayIdPers(entities.map((item) => item.id));
    setArrayPers(entities.map((item) => item.nomPrenom));
  }

  async function getReps() {
    let object = {};
    let arrayOption = [];
    let users = await dispatch(fetchReps());
    let entities = users.payload;
    arrayOption.push({ value: 0, label: "Rep", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      object[e.id] = { value: e.id, label: e.nom };
    });
    setRepSelect(object);
    setRepsOptions(arrayOption);
    setArrayReps(entities.map((item) => item.nom));
    setArrayIdReps(entities.map((item) => item.id));
  }

  async function getAnnees() {
    var arrayOption = [];
    var annee = await dispatch(fetchAnneeIms());
    var entities = annee.payload;
    arrayOption.push({ value: 0, label: "Année", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.annee });
    });
    setAnneeOptions(arrayOption);
  }

  async function getCycles() {
    let objCycle = {};
    let arrayOption = [];
    let cycle = await dispatch(fetchCycle());
    let entities = cycle.payload.cycles;
    arrayOption.push({ value: 0, label: "Cycle", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.cycles.id, label: e.cycles.designation });
      objCycle[e.id] = { value: e.cycles.id, label: e.cycles.designation };
    });
    setCycleSelect(objCycle);
    setCyclesOptions(arrayOption);
    setArrayCycles(entities.map((item) => item.cycles.designation));
    setArrayIdCycles(entities.map((item) => item.cycles.id));
  }

  async function getLines() {
    let object3 = {};
    let arrayOption = [];
    let lines = await dispatch(fetchLine());
    let entities = lines.payload;
    arrayOption.push({ value: 0, label: "Line", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      object3[e.id] = { value: e.id, label: e.nom };
    });
    setLineSelect(object3);
    setLinesOptions(arrayOption);
    setArrayIdLines(entities.map((item) => item.id));
    setArrayLines(entities.map((item) => item.nom));
  }

  async function getBricks() {
    let object4 = {};
    let arrayOption = [];
    let lines = await dispatch(fetchBrick());
    let entities = lines.payload;
    arrayOption.push({ value: 0, label: "Brick", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      object4[e.id] = { value: e.id, label: e.nom };
    });
    setBrickSelect(object4);
    setBricksOptions(arrayOption);
    setArrayBrick(entities.map((item) => item.nom));
    setArrayIdBrick(entities.map((item) => item.id));
  }

  async function getSpecialites() {
    var objSp = {};
    var arrayOption = [];
    var sp = await dispatch(fetchSpecialite());
    var entities = sp.payload;
    arrayOption.push({ value: 0, label: "Spécialité", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      objSp[e.id] = { value: e.id, label: e.nom };
    });
    setSpecialitSelect(objSp);
    setSpecialiteOptions(arrayOption);
    setArrayIdSp(entities.map((item) => item.id));
    setArraySp(entities.map((item) => item.nom));
  }

  const processJsonData = (jsondata) => {
    // document.getElementById("loaderTable").classList.remove("hidden");
    const finalData = jsondata.map((item) => {
      if (item.line) {
        const index = arrayLines.indexOf(item.line.toString().trim());

        if (index >= 0) {
          item.id_line = arrayIdLines[index];
          item.line = arrayLines[index].toUpperCase();
        } else {
          item.id_line = null;
        }
      }

      if (item.rep) {
        const index = arrayReps.indexOf(item.rep.toString().trim());
        if (index >= 0) {
          item.id_rep = arrayIdReps[index];
          item.rep = arrayReps[index].toUpperCase();
        } else {
          item.id_rep = null;
        }
      }

      if (item.cycle) {
        const index = arrayCycles.indexOf(item.cycle.toString().trim());
        if (index >= 0) {
          item.id_cycle = arrayIdCycles[index];
          item.cycle = arrayCycles[index].toUpperCase();
        } else {
          item.id_cycle = null;
        }
      }

      if (item.contact) {
        const index = arrayPers.indexOf(item.contact.toString().trim());
        if (index >= 0) {
          item.id_contact = arrayIdPers[index];
          item.contact = arrayPers[index].toUpperCase();
        } else {
          item.id_contact = null;
        }
      }

      if (item.specialite) {
        const index = arraySp.indexOf(item.specialite.toString().trim());
        if (index >= 0) {
          item.id_specialite = arrayIdSp[index];
          item.specialite = arraySp[index].toUpperCase();
        } else {
          item.id_specialite = null;
        }
      }

      if (item.brick) {
        const index = arrayBrick.indexOf(item.brick.toString().trim());
        if (index >= 0) {
          item.id_brick = arrayIdBrick[index];
          item.brick = arrayBrick[index].toUpperCase();
        } else {
          item.id_brick = null;
        }
      }

      return item;
    });

    if (finalData.length > 0) {
      setEntities(finalData);
      document.getElementById("loaderTable").classList.add("hidden");
    } else {
      document.getElementById("loaderTable").classList.add("hidden");
      notify(2, "Vérifier votre fichier !");
    }
  };

  useEffect(() => {
    getCycles();
    getContacts();
    getReps();
    getLines();
    getBricks();
    getAnnees();
    getSpecialites();
  }, [location.id, dispatch]);

  function listData() {
    navigate.push("/ListPltData");
  }
  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12">
                <Button
                  id="returnBtn"
                  className="btn-wd  mr-1 float-left"
                  type="button"
                  variant="info"
                  onClick={listData}
                >
                  <span id="returnSpan" className="btn-label">
                    <i className="fas fa-list"></i>
                  </span>
                  Retour à la liste
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">{"Ajouter données PLT"}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Fichier * </label>
                            <Form.Control
                              accept=".xls, .xlsx, .csv"
                              placeholder="Image"
                              type="file"
                              onChange={(value) => {
                                fileHandler(value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group id="moisClass">
                            <label> Année * </label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={anneeSelect}
                              onChange={(value) => {
                                setAnneeSelect(value);
                              }}
                              options={anneeOptions}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pl-1" md="6">
                          <Form.Group id="cycleClass">
                            <label> Cycle * </label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={cycle}
                              onChange={(value) => {
                                setCycle(value);
                              }}
                              options={cyclesOptions}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <div className="text-center">
                        <img
                          id="loaderTable"
                          className="hidden"
                          src={require("../../../assets/img/loader.gif")}
                          alt="loader"
                        />
                      </div>
                      <div
                        className="scroll-sync"
                        onScroll={(e) => {
                          document.querySelector(".table-scroll").scrollLeft =
                            e.target.scrollLeft;
                        }}
                      >
                        <div style={{ width: "2000px" }}></div>
                      </div>
                      <br></br>
                      <div className="table-responsive">
                        <table
                          className={`table table-striped table-scroll
                          }`}
                          style={{
                            display: entities.length > 0 ? "none" : "",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>Line</th>
                              <th>Territoire</th>
                              <th>Matricule</th>
                              <th>Rep</th>
                              <th>Cycle</th>
                              <th>Semaine</th>
                              <th>Jour</th>
                              <th>TypeActivite</th>
                              <th>Contact</th>
                              <th>Spécialité</th>
                              <th>Brick</th>
                              <th>Commune</th>
                              <th>Segment</th>
                              <th>VilleBase</th>
                              <th>Fréquence</th>
                              <th>Distance</th>
                              <th>CodeOneKey</th>
                              <th>BaseDéplacement</th>
                              <th>TypePoste</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      <br></br>
                      <Button
                        className="btn-wd btn mr-1 float-right extraction"
                        id="blExt"
                        type="button"
                        variant="warning"
                        style={{
                          display: entities.length === 0 ? "block" : "none",
                          float: "right",
                        }}
                        disabled={disabled}
                        onClick={() => {
                          if (anneeSelect.value !== "" && cycle.value !== "") {
                            showTable(
                              anneeSelect.value,
                              cycle.label,
                              cycle.value
                            );
                          } else {
                            notify(2, "Veuillez choisir l'année et le cycle");
                          }
                        }}
                      >
                        <span className="btn-label">
                          <i className="fas fa-cogs"></i>
                        </span>
                        Extraction
                      </Button>
                      <br></br>
                      <div className="table-responsive table-scroll">
                        <table
                          className="table table-striped"
                          style={{
                            display: entities.length === 0 ? "none" : "",
                          }}
                        >
                          <thead className="fixed-header">
                            <tr>
                              <th></th>
                              <th>Line</th>
                              <th>Territoire</th>
                              <th>Matricule</th>
                              <th>Rep</th>
                              <th>Cycle</th>
                              <th>Semaine</th>
                              <th>Jour</th>
                              <th>TypeActivite</th>
                              <th>Contact</th>
                              <th>Spécialité</th>
                              <th>Brick</th>
                              <th>Commune</th>
                              <th>Segment</th>
                              <th>VilleBase</th>
                              <th>Fréquence</th>
                              <th>Distance</th>
                              <th>CodeOneKey</th>
                              <th>Base Déplacement</th>
                              <th>Type Poste</th>
                            </tr>
                          </thead>
                          <tbody>
                            {entities.map((val, key) => {
                              return (
                                <tr key={key}>
                                  <td> {val.index} </td>
                                  <td
                                    className={
                                      val.testLine !== undefined
                                        ? "td-select"
                                        : ""
                                    }
                                  >
                                    {val.testLine === undefined ? (
                                      val.line
                                    ) : (
                                      <div style={{ display: "grid" }}>
                                        <p className="parag-td">{val.line}</p>
                                        <Select
                                          className="react-select primary"
                                          classNamePrefix="react-select"
                                          value={lineSelect[val.id_line]}
                                          onChange={(value) => {
                                            var list = [...entities];
                                            list[key] = {
                                              ...list[key],
                                              id_line: value.value,
                                            };
                                            setEntities(list);
                                            setLineSelect(value);
                                          }}
                                          options={linesOptions}
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td>{val.territoire}</td>
                                  <td>{val.matricule}</td>
                                  <td>
                                    {val.testRep === undefined ? (
                                      <>{val.rep}</>
                                    ) : (
                                      <div style={{ display: "grid" }}>
                                        <p className="parag-td">{val.rep}</p>
                                        <Select
                                          className="react-select primary"
                                          classNamePrefix="react-select"
                                          value={repSelect[val.id_rep]}
                                          onChange={(value) => {
                                            var list = [...entities];
                                            list[key] = {
                                              ...list[key],
                                              id_rep: value.value,
                                            };
                                            setEntities(list);
                                            setRepSelect(value);
                                          }}
                                          options={repsOptions}
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td
                                    className={
                                      val.testCycle !== undefined
                                        ? "td-select"
                                        : ""
                                    }
                                  >
                                    {val.testCycle === undefined ? (
                                      val.cycle
                                    ) : (
                                      <div style={{ display: "grid" }}>
                                        <p className="parag-td">{val.cycle}</p>
                                        <Select
                                          className="react-select primary"
                                          classNamePrefix="react-select"
                                          value={cycleSelect[val.id_cycle]}
                                          onChange={(value) => {
                                            var list = [...entities];
                                            list[key] = {
                                              ...list[key],
                                              id_cycle: value.value,
                                            };
                                            setEntities(list);
                                            setCycleSelect(value);
                                          }}
                                          options={cyclesOptions}
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td>{val.semaine}</td>
                                  <td>{val.jour}</td>
                                  <td>{val.typeActivite}</td>
                                  <td
                                    className={
                                      val.testContact !== undefined
                                        ? "td-select"
                                        : ""
                                    }
                                  >
                                    {val.testContact === undefined ? (
                                      val.contact
                                    ) : (
                                      <div style={{ display: "grid" }}>
                                        <p className="parag-td">
                                          {val.contact}
                                        </p>
                                        <Select
                                          className="react-select primary"
                                          classNamePrefix="react-select"
                                          value={contactSelect[val.id_contact]}
                                          onChange={(value) => {
                                            var list = [...entities];
                                            list[key] = {
                                              ...list[key],
                                              id_contact: value.value,
                                            };
                                            setEntities(list);
                                            setContactSelect(value);
                                          }}
                                          options={contactOptions}
                                        />
                                      </div>
                                    )}
                                  </td>

                                  <td
                                    className={
                                      val.testSpecialite !== undefined
                                        ? "td-select"
                                        : ""
                                    }
                                  >
                                    {val.testSpecialite === undefined ? (
                                      val.specialite
                                    ) : (
                                      <div style={{ display: "grid" }}>
                                        <p className="parag-td">
                                          {val.specialite}
                                        </p>
                                        <Select
                                          className="react-select primary"
                                          classNamePrefix="react-select"
                                          value={
                                            specialitSelect[val.id_specialite]
                                          }
                                          onChange={(value) => {
                                            var list = [...entities];
                                            list[key] = {
                                              ...list[key],
                                              id_specialite: value.value,
                                            };
                                            setEntities(list);
                                            setSpecialitSelect(value);
                                          }}
                                          options={specialiteOptions}
                                        />
                                      </div>
                                    )}
                                  </td>

                                  <td
                                    className={
                                      val.testBrick !== undefined
                                        ? "td-select"
                                        : ""
                                    }
                                  >
                                    {val.testBrick === undefined ? (
                                      <>{val.brick}</>
                                    ) : (
                                      <div style={{ display: "grid" }}>
                                        <p className="parag-td">{val.brick}</p>
                                        <Select
                                          className="react-select primary"
                                          classNamePrefix="react-select"
                                          value={brickSelect[val.id_brick]}
                                          onChange={(value) => {
                                            var list = [...entities];
                                            list[key] = {
                                              ...list[key],
                                              id_brick: value.value,
                                            };
                                            setEntities(list);
                                            setBrickSelect(value);
                                          }}
                                          options={bricksOptions}
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td>{val.commune}</td>
                                  <td>{val.segment}</td>
                                  <td>{val.villeBase}</td>
                                  <td>{val.frequence}</td>
                                  <td>{val.distance}</td>
                                  <td>{val.codeOneKey}</td>
                                  <td>{val.baseDeplacement}</td>
                                  <td>{val.typePoste}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <br></br>
                      <hr></hr>
                      {!testBtn ? (
                        <Button
                          className="btn-fill pull-right"
                          type="button"
                          variant="info"
                          id="saveAdd"
                          onClick={() =>
                            saveTable(
                              entities,
                              uploadFile,
                              cycle.value,
                              anneeSelect.value
                            )
                          }
                          style={{
                            display: entities.length === 0 ? "none" : "",
                          }}
                        >
                          Enregistrer
                        </Button>
                      ) : (
                        ""
                      )}
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default InsertPltData;
