import { createAsyncThunk } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");


export const fetchData = createAsyncThunk("data/fetchData", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "data/alldata", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
      body: JSON.stringify(action),
    });
    const data = await response.json();
    return data;
  });


  export const verifData = createAsyncThunk("data/verifData", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "data/verifData", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
      body: JSON.stringify(action),
    });
    const data = await response.json();
    return data;
  });
  


  export const dataAdded = createAsyncThunk("data/addData", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "data/addData", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const data = await response.json();
    return data;
  });


export const saveFile = createAsyncThunk("data/saveFile", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "data/saveFile", {
      method: 'POST',
      headers: {
        'Accept': 'application/*',
        'x-access-token': token,
      },
      body: action
    });
    const data = await response.json();
    return data;
  });


  export const getdata = createAsyncThunk("data/getdata", async (id1) => {
    const id = id1;
    const response = await fetch(Configuration.BACK_BASEURL + "data/getdata", {
      method: 'POST',
      headers: {
        'id': id,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': token
      },
  
    });
    const data = await response.json();
    return data;
  });

  
  
  export const DataDeleted = createAsyncThunk("data/delete", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "data/delete/"+action.id, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
    });
    const data = await response.json();
    return data;
  });
  

  
export const cheeckParams = createAsyncThunk("data/cheeckParams", async (action) => { 
  const response = await fetch(Configuration.BACK_BASEURL + "data/cheeckParams", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
    body: JSON.stringify(action)

  });
  const data = await response.json();
  return data;
});

    