import React, { useEffect } from "react";
import Select from "react-select";
// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";

import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { cycleAdded, cycleGetById } from "../../../Redux/cycleReduce";
import { fetchAnnee } from "../../../Redux/anneeReduce";

function AjouterCycle() {
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const dispatch = useDispatch();
  const location = useParams();
  const navigate = useHistory();
  const [designation, setDesignation] = React.useState("");
  const [mois, setMois] = React.useState([]);
  const [optionsMois] = React.useState([
    {
      value: "",
      label: "Mois",
      isDisabled: true,
    },
    {
      value: 1,
      label: "Janvier",
    },
    {
      value: 2,
      label: "Février",
    },
    {
      value: 3,
      label: "Mars",
    },
    {
      value: 4,
      label: "Avril",
    },
    {
      value: 5,
      label: "Mai",
    },
    {
      value: 6,
      label: "Juin",
    },
    {
      value: 7,
      label: "Juillet",
    },
    {
      value: 8,
      label: "Août",
    },
    {
      value: 9,
      label: "Septembre",
    },
    {
      value: 10,
      label: "Octobre",
    },
    {
      value: 11,
      label: "Novembre",
    },
    {
      value: 12,
      label: "Décembre",
    },
  ]);

  const [anneeOptions, setAnneeOptions] = React.useState([
    {
      value: null,
      label: "Année",
      isDisabled: true,
    },
  ]);
  const [anneeSelect, setAnneeSelect] = React.useState({
    value: null,
    label: "Année",
  });

  const [testBtn, setTestBtn] = React.useState(false);
  const [id, setId] = React.useState(0);

  function submitForm() {
    if (designation !== "" && mois.length > 0 && anneeSelect.value !== null) {
      const annee = anneeSelect.label;
      setTestBtn(true);
      dispatch(cycleAdded({ designation, annee, mois, id }));
      if (isNaN(location.id) === true) notify(1, "Insertion avec succès");
      else notify(1, "Modifié avec succès");
      setTimeout(async () => {
        listCycle();
      }, 1500);
    } else {
      notify(2, "Vérifier vos données");
    }
  }

  async function getAnnees() {
    var arrayOption = [];
    var annee = await dispatch(fetchAnnee());
    var entities = annee.payload;
    arrayOption.push({ value: 0, label: "Année", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.annee });
    });
    setAnneeOptions(arrayOption);
  }

  useEffect(() => {
    async function getCycle() {
      if (isNaN(location.id) === false) {
        var cycle = await dispatch(cycleGetById(location.id));
        var entities = cycle.payload.cyclee;
        var arrayOption = cycle.payload.arrayOption;
        setDesignation(entities.designation);
        setMois(arrayOption);
        setId(location.id);
      }
    }
    getCycle();
    getAnnees();
  }, [location.id, dispatch, optionsMois]);

  function listCycle() {
    navigate.push("/listCycle");
  }
  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12">
                <Button
                  id="returnBtn"
                  className="btn-wd  mr-1 float-left"
                  type="button"
                  variant="info"
                  onClick={listCycle}
                >
                  <span id="returnSpan" className="btn-label">
                    <i className="fas fa-list"></i>
                  </span>
                  Retour à la liste
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">
                        {typeof location.id == "undefined"
                          ? "Ajouter cycle"
                          : "Modifier cycle"}
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Désignation * </label>
                            <Form.Control
                              defaultValue={designation}
                              placeholder="Désignation "
                              type="text"
                              onChange={(value) => {
                                setDesignation(value.target.value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col className="pl-1" md="6">
                          <Form.Group id="moisClass">
                            <label> Mois * </label>
                            <Select
                              isMulti
                              placeholder="Mois"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={mois}
                              onChange={(value) => {
                                setMois(value);
                              }}
                              options={optionsMois}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pl-1" md="6">
                          <Form.Group id="moisClass">
                            <label> Année * </label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={anneeSelect}
                              onChange={(value) => {
                                setAnneeSelect(value);
                              }}
                              options={anneeOptions}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      {!testBtn ? (
                        <Button
                          className="btn-fill pull-right"
                          type="button"
                          variant="info"
                          id="saveAdd"
                          onClick={submitForm}
                        >
                          Enregistrer
                        </Button>
                      ) : (
                        ""
                      )}
                      <div className="clearfix"></div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default AjouterCycle;
