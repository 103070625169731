import React, { useEffect, useRef } from "react";

// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { ExcelRenderer } from "react-excel-renderer";
import {
  imsDataAdded,
  saveFile,
  cheeckParams,
  verifIms,
} from "../../../Redux/imsDataReduce";
import { fetchReps } from "../../../Redux/usersReduce";
import { fetchProduit } from "../../../Redux/produitReduce";
import { fetchLine } from "../../../Redux/lineReduce";
import { fetchBrick } from "../../../Redux/brickReduce";
import { fetchCycle } from "../../../Redux/cycleReduce";
import { fetchMarche } from "../../../Redux/marcheReduce";
import { fetchAnneeIms } from "../../../Redux/anneeReduce";

function InsertImsData() {
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const dispatch = useDispatch();
  const location = useParams();
  const navigate = useHistory();
  const [fileName, setFileName] = React.useState("");
  const [entities, setEntities] = React.useState([]);
  const [uploadFile, setUploadFile] = React.useState("");
  const [testBtn, setTestBtn] = React.useState(false);
  const saveTableCalled = useRef(false);

  const [typeOptions] = React.useState([
    {
      value: "",
      label: "Type",
      isDisabled: true,
    },
    {
      value: "1",
      label: "Ventes IMS",
    },
    {
      value: "2",
      label: "Deploiement",
    },
    {
      value: "3",
      label: "Produit-Line",
    },
    // {
    //   value: "4",
    //   label: "Line Données régionales",
    // },
  ]);
  const [typeSelect, setTypeSelect] = React.useState({
    value: 0,
    label: "Type",
  });

  const [repsOptions, setRepsOptions] = React.useState([
    {
      value: "",
      label: "Rep",
      isDisabled: true,
    },
  ]);
  const [repSelect, setRepSelect] = React.useState({
    value: 0,
    label: "Rep",
  });

  const [productOptions, setProductOptions] = React.useState([
    {
      value: "",
      label: "Produit",
      isDisabled: true,
    },
  ]);
  const [productSelect, setProductSelect] = React.useState({
    value: 0,
    label: "Produit",
  });

  const [linesOptions, setLinesOptions] = React.useState([
    {
      value: "",
      label: "Line",
      isDisabled: true,
    },
  ]);
  const [lineSelect, setLineSelect] = React.useState({
    value: 0,
    label: "Line",
  });

  const [bricksOptions, setBricksOptions] = React.useState([
    {
      value: "",
      label: "Brick",
      isDisabled: true,
    },
  ]);
  const [brickSelect, setBrickSelect] = React.useState({
    value: 0,
    label: "Brick",
  });

  const [marchesOptions, setMarchesOptions] = React.useState([
    {
      value: "",
      label: "Marché",
      isDisabled: true,
    },
  ]);
  const [marcheSelect, setMarcheSelect] = React.useState({
    value: 0,
    label: "Marché",
  });

  const [cyclesOptions, setCyclesOptions] = React.useState([
    {
      value: "",
      label: "Cycle",
      isDisabled: true,
    },
  ]);
  const [cycleSelect, setCycleSelect] = React.useState({
    value: 0,
    label: "Cycle",
  });

  const [mois, setMois] = React.useState({
    value: "",
    label: "Mois",
    isDisabled: true,
  });
  const [optionsMois] = React.useState([
    {
      value: "",
      label: "Mois",
      isDisabled: true,
    },
    {
      value: 1,
      label: "Janvier",
    },
    {
      value: 2,
      label: "Février",
    },
    {
      value: 3,
      label: "Mars",
    },
    {
      value: 4,
      label: "Avril",
    },
    {
      value: 5,
      label: "Mai",
    },
    {
      value: 6,
      label: "Juin",
    },
    {
      value: 7,
      label: "Juillet",
    },
    {
      value: 8,
      label: "Août",
    },
    {
      value: 9,
      label: "Septembre",
    },
    {
      value: 10,
      label: "Octobre",
    },
    {
      value: 11,
      label: "Novembre",
    },
    {
      value: 12,
      label: "Décembre",
    },
  ]);

  const [anneeOptions, setAnneeOptions] = React.useState([
    {
      value: "",
      label: "Année",
      isDisabled: true,
    },
  ]);
  const [anneeSelect, setAnneeSelect] = React.useState({
    value: "",
    label: "Année",
  });

  const fileHandler = (e) => {
    let fileObj = e.target.files[0];
    if (
      fileObj.name.slice(-5) === ".xlsx" ||
      fileObj.name.slice(-4) === ".xls" ||
      fileObj.name.slice(-4) === ".csv"
    ) {
      setUploadFile(fileObj);
      var str = fileObj.name.replace(/[^a-zA-Z0-9,;\-.!? ]/g, "");
      setFileName(str);
    } else {
      notify(2, "Votre fichier doit être de type CSV !");
    }
  };

  const showTable = React.useCallback(
    async (mois, annee) => {
      try {
        if (fileName !== "") {
          ExcelRenderer(uploadFile, (err, resp) => {
            if (err) {
              console.log("error", err);
            } else {
              verifLine(resp.rows, mois, annee);
            }
          });
        } else {
          notify(2, "Vérifiez vos données");
        }
      } catch (error) {
        console.error(error);
      }
    },
    [fileName, uploadFile]
  );

  const verifLine = (array, mois, annee) => {
    document.getElementById("loaderTable").classList.remove("hidden");
    let newArray = array.filter((arr) => arr.length > 0);
    var dataArray = [];
    var elementsCheck = [];
    var testHeader = null;
    if (typeSelect.value == 1) {
      elementsCheck = [
        "Cycle",
        "TypeMesure",
        "BRICKS",
        "MARKET",
        "Mois",
        "Trimestre",
        "YTD",
        "MAT",
        "AnneeMois",
        "Product",
        "Mesure",
        "MesureN_1",
        "MesureMarche",
        "MesureN_1Marche",
        "MesureNatProduit",
        "MesureN_1NatProduit",
        "MesureNatMarche",
        "MesureN_1NatMarche",
      ];
      testHeader = elementsCheck.every((element) =>
        newArray[0].includes(element)
      );
    } else if (typeSelect.value == 2) {
      elementsCheck = [
        "Line",
        "TerritoireFLM",
        "FLM",
        "TerritoireAM",
        "AM",
        "Territoire",
        "Rep",
        "CodeBrick",
        "Brick",
        "DateDebut",
        "DateFin",
      ];
      testHeader = elementsCheck.every((element) =>
        newArray[0].includes(element)
      );
    } else if (typeSelect.value == 3) {
      elementsCheck = ["Line", "Produit"];
      testHeader = elementsCheck.every((element) =>
        newArray[0].includes(element)
      );
    } else {
      elementsCheck = ["Line", "Territoire", "LineHybride"];
      testHeader = elementsCheck.every((element) =>
        newArray[0].includes(element)
      );
    }

    if (testHeader) {
      newArray.slice(1).forEach((element, index) => {
        var objPP = {};
        if (element.length > 0) {
          if (typeSelect.value == 1) {
            objPP = {
              cycle: element[0],
              typeMesure: element[1],
              brick: element[2],
              market: element[3],
              mois: element[4],
              trimstre: element[5],
              ytd: element[6],
              mat: element[7],
              anneeMois: element[8],
              produit: element[9],
              mesure: element[10],
              mesureN_1: element[11],
              mesureMarche: element[12],
              mesureN_1Marche: element[13],
              mesureNatProduit: element[14],
              mesureN_1NatProduit: element[15],
              mesureNatMarche: element[16],
              mesureN_1NatMarche: element[17],
            };
          } else if (typeSelect.value == 2) {
            const annee = element[9].toString().substring(0, 4);
            const mois = element[9].toString().substring(4, 6);
            const jour = element[9].toString().substring(6, 8);
            const dateDebut = `${annee}-${mois}-${jour}`;
            const annee1 = element[10].toString().substring(0, 4);
            const mois1 = element[10].toString().substring(4, 6);
            const jour1 = element[10].toString().substring(6, 8);
            const dateFin = `${annee1}-${mois1}-${jour1}`;
            objPP = {
              linee: element[0],
              territoireFLM: element[1],
              flm: element[2],
              territoireAm: element[3],
              am: element[4],
              territoire: element[5],
              rep: element[6],
              codeBrick: element[7],
              brick: element[8],
              dateDebut: dateDebut,
              dateFin: dateFin,
            };
          } else if (typeSelect.value == 3) {
            objPP = {
              line: element[0],
              produit: element[1],
            };
          } else {
            objPP = {
              line: element[0],
              territoire: element[1],
              lineHybride: element[2],
            };
          }
        }
        dataArray.push(objPP);
      });
      dispatch(verifIms({ annee: annee, mois: mois })).then((res) => {
        if (res.payload === true) {
          dispatch(
            cheeckParams({ data: dataArray, type: typeSelect.value })
          ).then((rows) => {
            document.getElementById("loaderTable").classList.add("hidden");
            setEntities(rows.payload);
          });
        } else {
          document.getElementById("loaderTable").classList.add("hidden");
          notify(2, res.payload.msg);
        }
      });
    } else {
      document.getElementById("loaderTable").classList.add("hidden");
      notify(2, "Vérifier votre fichier !");
    }
  };

  const saveTable = React.useCallback(
    async (entities, uploadFile, type, moisVal, anneeVal) => {
      if (saveTableCalled.current) return;
      saveTableCalled.current = true;
      var verif = true;

      var verifMois = true;
      if (type == 1 && moisVal == "") {
        verifMois = false;
      }

      //Keys Produits
      var keys = entities
        .filter((element) => element.id_produit === "")
        .map((element) => element.index);
      var keysProd = keys.join("-");

      //Keys Cycles
      var keys2 = entities
        .filter((element) => element.id_cycle === "")
        .map((element) => element.index);
      var keysCycle = keys2.join("-");

      //Keys Bricks
      var keys3 = entities
        .filter((element) => element.id_brick === "")
        .map((element) => element.index);
      var keysBrick = keys3.join("-");

      //Keys Market
      var keys4 = entities
        .filter((element) => element.id_market === "")
        .map((element) => element.index);
      var keysMarket = keys4.join("-");

      // Keys Rep
      var keys5 = entities
        .filter((element) => element.id_rep === "")
        .map((element) => element.index);
      var keysRep = keys5.join("-");

      // Keys Line
      var keys6 = entities
        .filter((element) => element.id_line === "")
        .map((element) => element.index);
      var keysLine = keys6.join("-");

      var checks = [];

      if (type == 1) {
        checks = [
          {
            keys: keys,
            message: "Veuillez choisir les produits des lignes " + keysProd,
          },
          {
            keys: keys2,
            message: "Veuillez choisir les cycles des lignes " + keysCycle,
          },
          {
            keys: keys3,
            message: "Veuillez choisir les bricks des lignes " + keysBrick,
          },
          {
            keys: keys4,
            message: "Veuillez choisir les marchés des lignes " + keysMarket,
          },
        ];
        checks.forEach((check) => {
          if (check.keys.length > 0) {
            verif = false;
            notify(2, check.message);
          }
        });
      } else if (type == 2) {
        checks = [
          {
            keys: keys5,
            message: "Veuillez choisir les reps des lignes " + keysRep,
          },

          {
            keys: keys3,
            message: "Veuillez choisir les bricks des lignes " + keysBrick,
          },
        ];
        checks.forEach((check) => {
          if (check.keys.length > 0) {
            verif = false;
            notify(2, check.message);
          }
        });
      } else {
        checks = [
          {
            keys: keys6,
            message: "Veuillez choisir les lines des lignes " + keysLine,
          },
          {
            keys: keys,
            message: "Veuillez choisir les produits des lignes " + keysProd,
          },
        ];
        checks.forEach((check) => {
          if (check.keys.length > 0) {
            verif = false;
            notify(2, check.message);
          }
        });
      }

      if (
        uploadFile &&
        entities.length > 0 &&
        verif &&
        verifMois &&
        anneeVal !== ""
      ) {
        document.getElementById("loaderTable").classList.remove("hidden");
        const dataArray = new FormData();
        dataArray.append("file", uploadFile);
        dataArray.append("name", uploadFile.name);
        dispatch(saveFile(dataArray)).then((e) => {
          setTestBtn(true);
          var filename = e.payload.filename;
          dispatch(
            imsDataAdded({
              file: filename,
              namefile: uploadFile.name,
              lignes: entities,
              type: type,
              mois: moisVal,
              annee: anneeVal,
            })
          ).then((e) => {
            if (e.payload) {
              document.getElementById("loaderTable").classList.add("hidden");
              notify(1, "Insertion avec succés !");
              setTimeout(() => {
                listImsData();
              }, 1300);
            } else {
              document.getElementById("loaderTable").classList.add("hidden");
              saveTableCalled.current = false;
              setTestBtn(false);
              notify(2, "Problème de connexion !");
            }
          });
        });
      } else {
        document.getElementById("loaderTable").classList.add("hidden");
        saveTableCalled.current = false;
        notify(2, "Vérifier vos données !");
      }
    },
    []
  );

  async function getReps() {
    var object = {};
    var arrayOption = [];
    var users = await dispatch(fetchReps());
    var entities = users.payload;
    arrayOption.push({ value: 0, label: "Rep", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      object[e.id] = { value: e.id, label: e.nom };
    });
    setRepSelect(object);
    setRepsOptions(arrayOption);
  }

  async function getProduits() {
    var object2 = {};
    var arrayOption = [];
    var produits = await dispatch(fetchProduit());
    var entities = produits.payload;
    arrayOption.push({ value: 0, label: "Produit", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      object2[e.id] = { value: e.id, label: e.nom };
    });
    setProductSelect(object2);
    setProductOptions(arrayOption);
  }

  async function getAnnees() {
    var arrayOption = [];
    var annee = await dispatch(fetchAnneeIms());
    var entities = annee.payload;
    arrayOption.push({ value: 0, label: "Année", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.annee });
    });
    setAnneeOptions(arrayOption);
  }

  async function getCycles() {
    var objCycle = {};
    var arrayOption = [];
    var cycle = await dispatch(fetchCycle());
    var entities = cycle.payload.cycles;
    arrayOption.push({ value: 0, label: "Cycle", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id_cycle, label: e.designation });
      objCycle[e.id] = { value: e.id_cycle, label: e.designation };
    });
    setCycleSelect(objCycle);
    setCyclesOptions(arrayOption);
  }

  async function getMarches() {
    var objMarche = {};
    var arrayOption = [];
    var produits = await dispatch(fetchMarche());
    var entities = produits.payload;
    arrayOption.push({ value: 0, label: "Marché", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.designation });
      objMarche[e.id] = { value: e.id, label: e.designation };
    });
    setMarcheSelect(objMarche);
    setMarchesOptions(arrayOption);
  }

  async function getLines() {
    var object3 = {};
    var arrayOption = [];
    var lines = await dispatch(fetchLine());
    var entities = lines.payload;
    arrayOption.push({ value: 0, label: "Line", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      object3[e.id] = { value: e.id, label: e.nom };
    });
    setLineSelect(object3);
    setLinesOptions(arrayOption);
  }

  async function getBricks() {
    var object4 = {};
    var arrayOption = [];
    var lines = await dispatch(fetchBrick());
    var entities = lines.payload;
    arrayOption.push({ value: 0, label: "Brick", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      object4[e.id] = { value: e.id, label: e.nom };
    });
    setBrickSelect(object4);
    setBricksOptions(arrayOption);
  }

  useEffect(() => {
    getReps();
    getProduits();
    getLines();
    getBricks();
    getCycles();
    getMarches();
    getAnnees();
  }, [location.id, dispatch]);

  function listImsData() {
    navigate.push("/ListImsData");
  }
  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12">
                <Button
                  id="returnBtn"
                  className="btn-wd  mr-1 float-left"
                  type="button"
                  variant="info"
                  onClick={listImsData}
                >
                  <span id="returnSpan" className="btn-label">
                    <i className="fas fa-list"></i>
                  </span>
                  Retour à la liste
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">{"Ajouter données IMS"}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col md="6">
                          <Form.Group id="typeClass">
                            <label>Type* </label>
                            <Select
                              placeholder="Line"
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={typeSelect}
                              onChange={(value) => {
                                setTypeSelect(value);
                              }}
                              options={typeOptions}
                            />
                          </Form.Group>
                        </Col>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Fichier * </label>
                            <Form.Control
                              accept=".xls, .xlsx, .csv"
                              placeholder="Image"
                              type="file"
                              onChange={(value) => {
                                fileHandler(value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pl-1" md="6">
                          <Form.Group id="moisClass">
                            <label> Année * </label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={anneeSelect}
                              onChange={(value) => {
                                setAnneeSelect(value);
                              }}
                              options={anneeOptions}
                            />
                          </Form.Group>
                        </Col>
                        {typeSelect.value == 1 && (
                          <Col className="pl-1" md="6">
                            <Form.Group id="moisClass">
                              <label> Mois * </label>
                              <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                value={mois}
                                onChange={(value) => {
                                  setMois(value);
                                }}
                                options={optionsMois}
                              />
                            </Form.Group>
                          </Col>
                        )}
                      </Row>

                      <br></br>
                      <br></br>
                      <div className="table-responsive">
                        <table
                          className={`table table-striped ${
                            typeSelect.value == 1 ? "small-table" : ""
                          }`}
                          style={{
                            display: entities.length > 0 ? "none" : "",
                          }}
                        >
                          <thead>
                            {typeSelect.value == 1 ? (
                              <tr>
                                <th> Product </th>
                                <th> Cycle </th>
                                <th> TypeMesure </th>
                                <th> BRICKS </th>
                                <th> MARKET </th>
                                <th> Mois </th>
                                <th> Trimestre </th>
                                <th> YTD </th>
                                <th> MAT </th>
                                <th> AnneeMois </th>
                                <th> Mesure </th>
                                <th> MesureN_1</th>
                                <th> MesureMarche </th>
                                <th> MesureN_1Marche </th>
                                <th> MesureNatProduit </th>
                                <th> MesureN_1NatProduit </th>
                                <th> MesureNatMarche </th>
                                <th> MesureN_1NatMarche </th>
                              </tr>
                            ) : typeSelect.value == 2 ? (
                              <tr>
                                <th> Line </th>
                                <th> TerritoireFLM </th>
                                <th> FLM </th>
                                <th> TerritoireAM</th>
                                <th> AM </th>
                                <th> Territoire </th>
                                <th> Rep </th>
                                <th> CodeBrick </th>
                                <th> Brick </th>
                                <th> DateDebut </th>
                                <th> DateFin </th>
                              </tr>
                            ) : typeSelect.value == 3 ? (
                              <tr>
                                <th> Line </th>
                                <th> Produit </th>
                              </tr>
                            ) : typeSelect.value == 4 ? (
                              <tr>
                                <th> Line </th>
                                <th> Territoire </th>
                                <th> LineHybride </th>
                              </tr>
                            ) : (
                              ""
                            )}
                          </thead>
                        </table>
                      </div>
                      <Button
                        className="btn-wd btn-outline mr-1 float-right extraction"
                        id="blExt"
                        type="button"
                        variant="info"
                        style={{
                          display: entities.length === 0 ? "block" : "none",
                          float: "right",
                        }}
                        onClick={() => {
                          const testType = typeSelect.value;
                          const testMois = mois.value !== "";
                          const testAnnee = anneeSelect.value !== "";
                          if (
                            (testType == 1 && testMois && testAnnee) ||
                            (testType == 2 && testAnnee) ||
                            (testType == 3 && testAnnee)
                          ) {
                            showTable(mois.value, anneeSelect.value);
                          } else {
                            notify(
                              2,
                              typeSelect.value == 1
                                ? "Veuillez choisir l'année et le mois"
                                : "Veuillez choisir l'année"
                            );
                          }
                        }}
                      >
                        <span className="btn-label">
                          <i className="fas fa-cogs"></i>
                        </span>
                        Extraction
                      </Button>
                      <br></br>
                      <div
                        className={
                          typeSelect.value == 1 ? "table-responsive" : ""
                        }
                      >
                        <table
                          className="table table-striped"
                          style={{
                            display: entities.length === 0 ? "none" : "",
                          }}
                        >
                          <thead>
                            {typeSelect.value == 1 ? (
                              <tr>
                                <th></th>
                                <th> Product </th>
                                <th> Cycle </th>
                                <th> TypeMesure </th>
                                <th> BRICKS </th>
                                <th> MARKET </th>
                                <th> Mois </th>
                                <th> Trimestre </th>
                                <th> YTD </th>
                                <th> MAT </th>
                                <th> AnneeMois </th>
                                <th> Mesure </th>
                                <th> MesureN_1</th>
                                <th> MesureMarche </th>
                                <th> MesureN_1Marche </th>
                                <th> MesureNatProduit </th>
                                <th> MesureN_1NatProduit </th>
                                <th> MesureNatMarche </th>
                                <th> MesureN_1NatMarche </th>
                              </tr>
                            ) : typeSelect.value == 2 ? (
                              <tr>
                                <th></th>
                                <th> Line </th>
                                <th> TerritoireFLM </th>
                                <th> FLM </th>
                                <th> TerritoireAM</th>
                                <th> AM </th>
                                <th> Territoire </th>
                                <th> Rep </th>
                                <th> CodeBrick </th>
                                <th> Brick </th>
                                <th> DateDebut </th>
                                <th> DateFin </th>
                              </tr>
                            ) : typeSelect.value == 3 ? (
                              <tr>
                                <th></th>
                                <th> Line </th>
                                <th> Produit </th>
                              </tr>
                            ) : typeSelect.value == 4 ? (
                              <tr>
                                <th> Line </th>
                                <th> Territoire </th>
                                <th> LineHybride </th>
                              </tr>
                            ) : (
                              ""
                            )}
                          </thead>
                          <tbody>
                            {entities.map((val, key) => {
                              return (
                                <tr key={key}>
                                  {typeSelect.value == 1 ? (
                                    <>
                                      <td>{val.index}</td>
                                      <td
                                        className={
                                          val.testProduit !== undefined
                                            ? "td-select"
                                            : ""
                                        }
                                      >
                                        {val.testProduit === undefined ? (
                                          val.produit
                                        ) : (
                                          <div style={{ display: "grid" }}>
                                            <p className="parag-td">
                                              {val.produit}
                                            </p>
                                            <Select
                                              className="react-select primary"
                                              classNamePrefix="react-select"
                                              value={
                                                productSelect[val.id_produit]
                                              }
                                              onChange={(value) => {
                                                var list = [...entities];
                                                list[key] = {
                                                  ...list[key],
                                                  id_produit: value.value,
                                                };
                                                setEntities(list);
                                                setProductSelect(value);
                                              }}
                                              options={productOptions}
                                            />
                                          </div>
                                        )}
                                      </td>
                                      <td
                                        className={
                                          val.testCycle !== undefined
                                            ? "td-select"
                                            : ""
                                        }
                                      >
                                        {val.testCycle === undefined ? (
                                          <>{val.cycle}</>
                                        ) : (
                                          <div style={{ display: "grid" }}>
                                            <p className="parag-td">
                                              {val.cycle}
                                            </p>
                                            <Select
                                              className="react-select primary"
                                              classNamePrefix="react-select"
                                              value={cycleSelect[val.id_cycle]}
                                              onChange={(value) => {
                                                var list = [...entities];
                                                list[key] = {
                                                  ...list[key],
                                                  id_cycle: value.value,
                                                };
                                                setEntities(list);
                                                setCycleSelect(value);
                                              }}
                                              options={cyclesOptions}
                                            />
                                          </div>
                                        )}
                                      </td>
                                      <td>{val.typeMesure}</td>
                                      <td
                                        className={
                                          val.testBrick !== undefined
                                            ? "td-select"
                                            : ""
                                        }
                                      >
                                        {val.testBrick === undefined ? (
                                          <>{val.brick}</>
                                        ) : (
                                          <div style={{ display: "grid" }}>
                                            <p className="parag-td">
                                              {val.brick}
                                            </p>
                                            <Select
                                              className="react-select primary"
                                              classNamePrefix="react-select"
                                              value={brickSelect[val.id_brick]}
                                              onChange={(value) => {
                                                var list = [...entities];
                                                list[key] = {
                                                  ...list[key],
                                                  id_brick: value.value,
                                                };
                                                setEntities(list);
                                                setBrickSelect(value);
                                              }}
                                              options={bricksOptions}
                                            />
                                          </div>
                                        )}
                                      </td>
                                      <td
                                        className={
                                          val.testMarket !== undefined
                                            ? "td-select"
                                            : ""
                                        }
                                      >
                                        {val.testMarket === undefined ? (
                                          <>{val.market}</>
                                        ) : (
                                          <div style={{ display: "grid" }}>
                                            <p className="parag-td">
                                              {val.market}
                                            </p>
                                            <Select
                                              className="react-select primary"
                                              classNamePrefix="react-select"
                                              value={
                                                marcheSelect[val.id_market]
                                              }
                                              onChange={(value) => {
                                                var list = [...entities];
                                                list[key] = {
                                                  ...list[key],
                                                  id_market: value.value,
                                                };
                                                setEntities(list);
                                                setMarcheSelect(value);
                                              }}
                                              options={marchesOptions}
                                            />
                                          </div>
                                        )}
                                      </td>
                                      <td>{val.mois}</td>
                                      <td>{val.trimstre}</td>
                                      <td>{val.ytd}</td>
                                      <td>{val.mat}</td>
                                      <td>{val.anneeMois}</td>
                                      <td>{val.mesure}</td>
                                      <td>{val.mesureN_1}</td>
                                      <td>{val.mesureMarche}</td>
                                      <td>{val.mesureN_1Marche}</td>
                                      <td>{val.mesureNatProduit}</td>
                                      <td>{val.mesureN_1NatProduit}</td>
                                      <td>{val.mesureNatMarche}</td>
                                      <td>{val.mesureN_1NatMarche}</td>
                                    </>
                                  ) : typeSelect.value == 2 ? (
                                    <>
                                      <td> {val.index} </td>
                                      <td>{val.linee}</td>
                                      <td>{val.territoireFLM}</td>
                                      <td>{val.flm}</td>
                                      <td>{val.territoireAm}</td>
                                      <td>{val.am}</td>
                                      <td>{val.territoire}</td>
                                      <td>
                                        {val.testRep === undefined ? (
                                          <>{val.rep}</>
                                        ) : (
                                          <div style={{ display: "grid" }}>
                                            <p className="parag-td">
                                              {val.rep}
                                            </p>
                                            <Select
                                              className="react-select primary"
                                              classNamePrefix="react-select"
                                              value={repSelect[val.id_rep]}
                                              onChange={(value) => {
                                                var list = [...entities];
                                                list[key] = {
                                                  ...list[key],
                                                  id_rep: value.value,
                                                };
                                                setEntities(list);
                                                setRepSelect(value);
                                              }}
                                              options={repsOptions}
                                            />
                                          </div>
                                        )}
                                      </td>
                                      <td>{val.codeBrick}</td>
                                      <td>
                                        {val.testBrick === undefined ? (
                                          <>{val.brick}</>
                                        ) : (
                                          <div style={{ display: "grid" }}>
                                            <p className="parag-td">
                                              {val.brick}
                                            </p>
                                            <Select
                                              className="react-select primary"
                                              classNamePrefix="react-select"
                                              value={brickSelect[val.id_brick]}
                                              onChange={(value) => {
                                                var list = [...entities];
                                                list[key] = {
                                                  ...list[key],
                                                  id_brick: value.value,
                                                };
                                                setEntities(list);
                                                setBrickSelect(value);
                                              }}
                                              options={bricksOptions}
                                            />
                                          </div>
                                        )}
                                      </td>
                                      <td>{val.dateDebut}</td>
                                      <td>{val.dateFin}</td>
                                    </>
                                  ) : typeSelect.value == 3 ? (
                                    <>
                                      <td>{val.index} </td>
                                      <td>
                                        {val.testLine === undefined ? (
                                          <>{val.line}</>
                                        ) : (
                                          <div style={{ display: "grid" }}>
                                            <p className="parag-td">
                                              {val.line}
                                            </p>
                                            <Select
                                              className="react-select primary"
                                              classNamePrefix="react-select"
                                              value={lineSelect[val.id_line]}
                                              onChange={(value) => {
                                                var list = [...entities];
                                                list[key] = {
                                                  ...list[key],
                                                  id_line: value.value,
                                                };
                                                setEntities(list);
                                                setLineSelect(value);
                                              }}
                                              options={linesOptions}
                                            />
                                          </div>
                                        )}
                                      </td>
                                      <td>
                                        {val.testProduit === undefined ? (
                                          val.produit
                                        ) : (
                                          <div style={{ display: "grid" }}>
                                            <p className="parag-td">
                                              {val.produit}
                                            </p>
                                            <Select
                                              className="react-select primary"
                                              classNamePrefix="react-select"
                                              value={
                                                productSelect[val.id_produit]
                                              }
                                              onChange={(value) => {
                                                var list = [...entities];
                                                list[key] = {
                                                  ...list[key],
                                                  id_produit: value.value,
                                                };
                                                setEntities(list);
                                                setProductSelect(value);
                                              }}
                                              options={productOptions}
                                            />
                                          </div>
                                        )}
                                      </td>
                                    </>
                                  ) : typeSelect.value == 4 ? (
                                    <>
                                      <td>{val.line}</td>
                                      <td>{val.territoire}</td>
                                      <td>{val.lineHybride}</td>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <br></br>
                      <hr></hr>
                      {!testBtn ? (
                        <Button
                          className="btn-fill pull-right"
                          type="button"
                          variant="info"
                          onClick={() =>
                            saveTable(
                              entities,
                              uploadFile,
                              typeSelect.value,
                              mois.value,
                              anneeSelect.value
                            )
                          }
                          style={{
                            display: entities.length === 0 ? "none" : "",
                          }}
                        >
                          Enregistrer
                        </Button>
                      ) : (
                        ""
                      )}
                      <div className="text-center">
                        <img
                          id="loaderTable"
                          className="hidden"
                          src={require("../../../assets/img/loader.gif")}
                          alt="loader"
                        />
                      </div>
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default InsertImsData;
