import React, { useEffect, useRef } from "react";

// react-bootstrap components
import { Button, Card, Form, Container, Row, Col } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { ExcelRenderer } from "react-excel-renderer";
import { fetchReps } from "../../../Redux/usersReduce";
import { fetchBrick } from "../../../Redux/brickReduce";
import { fetchCycle } from "../../../Redux/cycleReduce";
import { fetchAnneeIms } from "../../../Redux/anneeReduce";
import {
  cheeckParams,
  saveFile,
  verifData,
  dataAdded,
} from "../../../Redux/dataReduce";
import { fetchPersonnel } from "../../../Redux/personnelReduce";
import { fetchSpecialite } from "../../../Redux/specialiteReduce";

function InsertOceData() {
  const notify = (type, msg) => {
    if (type === 1)
      toast.success(
        <strong>
          <i className="fas fa-check-circle"></i>
          {msg}
        </strong>
      );
    else
      toast.error(
        <strong>
          <i className="fas fa-exclamation-circle"></i>
          {msg}
        </strong>
      );
  };
  const dispatch = useDispatch();
  const location = useParams();
  const navigate = useHistory();
  const [fileName, setFileName] = React.useState("");
  const [entities, setEntities] = React.useState([]);
  const [uploadFile, setUploadFile] = React.useState("");
  const [testBtn, setTestBtn] = React.useState(false);
  const [disabled, setDisabled] = React.useState(false);
  const saveTableCalled = useRef(false);

  const [repsOptions, setRepsOptions] = React.useState([
    {
      value: "",
      label: "Rep",
      isDisabled: true,
    },
  ]);
  const [repSelect, setRepSelect] = React.useState({
    value: 0,
    label: "Rep",
  });

  const [contactOptions, setContactOptions] = React.useState([
    {
      value: "",
      label: "Contact",
      isDisabled: true,
    },
  ]);
  const [contactSelect, setContactSelect] = React.useState({
    value: 0,
    label: "Contact",
  });

  const [specialiteOptions, setSpecialiteOptions] = React.useState([
    {
      value: "",
      label: "Spécialité",
      isDisabled: true,
    },
  ]);
  const [specialitSelect, setSpecialitSelect] = React.useState({
    value: 0,
    label: "Spécialité",
  });

  const [bricksOptions, setBricksOptions] = React.useState([
    {
      value: "",
      label: "Brick",
      isDisabled: true,
    },
  ]);
  const [brickSelect, setBrickSelect] = React.useState({
    value: 0,
    label: "Brick",
  });

  const [cyclesOptions, setCyclesOptions] = React.useState([
    {
      value: "",
      label: "Cycle",
      isDisabled: true,
    },
  ]);
  const [cycleSelect, setCycleSelect] = React.useState({
    value: 0,
    label: "Cycle",
  });

  const [mois, setMois] = React.useState({
    value: null,
    label: "Mois",
    isDisabled: true,
  });

  const [optionsMois] = React.useState([
    {
      value: null,
      label: "Mois",
      isDisabled: true,
    },
    {
      value: "01",
      label: "Janvier",
    },
    {
      value: "02",
      label: "Février",
    },
    {
      value: "03",
      label: "Mars",
    },
    {
      value: "04",
      label: "Avril",
    },
    {
      value: "05",
      label: "Mai",
    },
    {
      value: "06",
      label: "Juin",
    },
    {
      value: "07",
      label: "Juillet",
    },
    {
      value: "08",
      label: "Août",
    },
    {
      value: "09",
      label: "Septembre",
    },
    {
      value: "10",
      label: "Octobre",
    },
    {
      value: "11",
      label: "Novembre",
    },
    {
      value: "12",
      label: "Décembre",
    },
  ]);

  const [anneeOptions, setAnneeOptions] = React.useState([
    {
      value: null,
      label: "Année",
      isDisabled: true,
    },
  ]);
  const [anneeSelect, setAnneeSelect] = React.useState({
    value: null,
    label: "Année",
  });

  const fileHandler = (e) => {
    let fileObj = e.target.files[0];
    if (
      fileObj.name.slice(-5) === ".xlsx" ||
      fileObj.name.slice(-4) === ".xls" ||
      fileObj.name.slice(-4) === ".csv"
    ) {
      setUploadFile(fileObj);
      var str = fileObj.name.replace(/[^a-zA-Z0-9,;\-.!? ]/g, "");
      setFileName(str);
    } else {
      notify(2, "Votre fichier doit être de type CSV !");
    }
  };

  const showTable = React.useCallback(
    async (mois, annee, anneeVal) => {
      setDisabled(true);
      try {
        if (fileName !== "") {
          ExcelRenderer(uploadFile, (err, resp) => {
            if (err) {
              setDisabled(false);
              console.log("error", err);
            } else {
              verifEntities(resp.rows, mois, annee, anneeVal);
            }
          });
        } else {
          setDisabled(false);
          notify(2, "Vérifiez vos données");
        }
      } catch (error) {
        setDisabled(false);
        console.error(error);
      }
    },
    [fileName, uploadFile]
  );

  const verifEntities = (array, mois, annee, anneeVal) => {
    document.getElementById("loaderTable").classList.remove("hidden");
    let newArray = array.filter((arr) => arr.length > 0);
    var dataArray = [];
    var testHeader = null;

    const elementsCheck = [
      "CodeOneKeyEtablissement",
      "Etablissement",
      "Service",
      "CodeOneKey",
      "Contact",
      "Specialite1",
      "Statut",
      "DatePlanifiee",
      "DateVisite",
      "DateEnvoi",
      "CodeBrick",
      "Canal",
      "CodeVisite",
      "Rep",
      "Territoire",
      "Panel BD.Cycle",
      "Panel BD.Segment",
      "Panel BD.Specialite1",
      "Panel BD.Specialite2",
      "Panel BD.Service",
      "Panel BD.Brick",
    ];

    const newArr = newArray[0].map((element) => element.trim());
    testHeader = elementsCheck.every((element) => newArr.includes(element));

    if (testHeader) {
      newArray.slice(1).forEach((element, index) => {
        var objPP = {};
        if (element.length > 0) {
          //dateplan
          var dateP = "";
          if (typeof element[7] == "string") {
            dateP = element[7];
          } else {
            const excelDays = element[7] ? element[7] : "";
            const excelStartDate = new Date(1900, 0, 1);
            const actualDate = new Date(
              excelStartDate.getTime() + (excelDays - 2) * 24 * 60 * 60 * 1000
            );
            dateP = actualDate.toISOString("fr-FR");
          }

          //datevisite
          var dateV = "";
          if (typeof element[8] == "string") {
            dateV = element[8];
          } else {
            const excelDays = element[8] ? element[8] : "";
            const excelStartDate = new Date(1900, 0, 1);
            const actualDate = new Date(
              excelStartDate.getTime() + (excelDays - 2) * 24 * 60 * 60 * 1000
            );
            dateV = actualDate.toISOString("fr-FR");
          }

          //dateenvoie
          var dateE = "";
          if (typeof element[9] == "string") {
            dateE = element[9];
          } else {
            const excelDays = element[9] ? element[9] : "";
            const excelStartDate = new Date(1900, 0, 1);
            const actualDate = new Date(
              excelStartDate.getTime() + (excelDays - 2) * 24 * 60 * 60 * 1000
            );
            dateE = actualDate.toISOString("fr-FR");
          }

          objPP = {
            codeOnekeyEtab: element[0],
            etablissement: element[1],
            service: element[2],
            codeOneKey: element[3],
            contact: element[4],
            specialite: element[5],
            statut: element[6],
            datePlanifiee: dateP,
            dateVisite: dateV,
            dateEnvoi: dateE,
            codeBrick: element[10],
            canal: element[11],
            codeVisite: element[12],
            rep: element[13],
            territoire: element[14],
            cycle: element[15],
            panelSegment: element[16],
            panelSpecialite1: element[17],
            panelSpecialite2: element[18],
            panelService: element[19],
            brick: element[20],
          };
        }
        dataArray.push(objPP);
      });

      const checkArray = dataArray.filter(
        (row) =>
          parseInt(row.dateVisite.split("-")[0]) == parseInt(annee) &&
          parseInt(row.dateVisite.split("-")[1]) == parseInt(mois)
      );
      dispatch(verifData({ annee: anneeVal, mois: mois, type: 4 })).then(
        (res) => {
          if (res.payload === true) {
            if (checkArray.length > 0) {
              dispatch(cheeckParams({ data: checkArray })).then((rows) => {
                document.getElementById("loaderTable").classList.add("hidden");
                setEntities(rows.payload);
              });
            } else {
              setDisabled(false);
              document.getElementById("loaderTable").classList.add("hidden");
              notify(2, "Vérifier votre fichier !");
            }
          } else {
            setDisabled(false);
            document.getElementById("loaderTable").classList.add("hidden");
            notify(2, res.payload.msg);
          }
        }
      );
    } else {
      setDisabled(false);
      document.getElementById("loaderTable").classList.add("hidden");
      notify(2, "Vérifier votre fichier !");
    }
  };

  const saveTable = React.useCallback(
    async (entities, uploadFile, moisVal, anneeVal) => {
      if (saveTableCalled.current) return;
      saveTableCalled.current = true;
      var verif = true;

      //Keys Cycles
      const keys1 = entities
        .filter((element) => element.id_cycle === "")
        .map((element) => element.index);
      const keysCycle = keys1.join("-");

      //Keys Bricks
      const keys2 = entities
        .filter((element) => element.id_brick === "")
        .map((element) => element.index);
      const keysBrick = keys2.join("-");

      // Keys Rep
      const keys3 = entities
        .filter((element) => element.id_rep === "")
        .map((element) => element.index);
      const keysRep = keys3.join("-");

      // Keys Contact
      const keys4 = entities
        .filter((element) => element.id_contact === "")
        .map((element) => element.index);
      const keysCont = keys4.join("-");

      // Keys Specialite
      const keys5 = entities
        .filter((element) => element.id_specialite === "")
        .map((element) => element.index);
      const keysSp = keys5.join("-");

      const checks = [
        {
          keys: keys1,
          message: "Veuillez choisir les cycles des lignes " + keysCycle,
        },
        {
          keys: keys2,
          message: "Veuillez choisir les bricks des lignes " + keysBrick,
        },
        {
          keys: keys3,
          message: "Veuillez choisir les reps des lignes " + keysRep,
        },
        {
          keys: keys4,
          message: "Veuillez choisir les contacts des lignes " + keysCont,
        },
        // {
        //   keys: keys5,
        //   message: "Veuillez choisir les spécialités des lignes " + keysSp,
        // },
      ];
      checks.forEach((check) => {
        if (check.keys.length > 0) {
          verif = false;
          notify(2, check.message);
        }
      });

      if (
        uploadFile &&
        entities.length > 0 &&
        verif &&
        moisVal !== null &&
        anneeVal !== ""
      ) {
        document.getElementById("loaderTable").classList.remove("hidden");
        const dataArray = new FormData();
        dataArray.append("file", uploadFile);
        dataArray.append("name", uploadFile.name);
        dispatch(saveFile(dataArray)).then((e) => {
          setTestBtn(true);
          var filename = e.payload.filename;
          dispatch(
            dataAdded({
              file: filename,
              lignes: entities,
              type: 4,
              mois: moisVal,
              annee: anneeVal,
            })
          ).then((e) => {
            if (e.payload) {
              document.getElementById("loaderTable").classList.add("hidden");
              notify(1, "Insertion avec succés !");
              setTimeout(() => {
                listData();
              }, 1300);
            } else {
              document.getElementById("loaderTable").classList.add("hidden");
              saveTableCalled.current = false;
              setTestBtn(false);
              notify(2, "Problème de connexion !");
            }
          });
        });
      } else {
        document.getElementById("loaderTable").classList.add("hidden");
        saveTableCalled.current = false;
        notify(2, "Vérifier vos données !");
      }
    },
    []
  );

  async function getContacts() {
    let object = {};
    let arrayOption = [];
    let contacts = await dispatch(fetchPersonnel());
    let entities = contacts.payload;
    arrayOption.push({ value: 0, label: "Contact", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nomPrenom });
      object[e.id] = { value: e.id, label: e.nomPrenom };
    });
    setContactSelect(object);
    setContactOptions(arrayOption);
  }

  async function getReps() {
    var object = {};
    var arrayOption = [];
    var users = await dispatch(fetchReps());
    var entities = users.payload;
    arrayOption.push({ value: 0, label: "Rep", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      object[e.id] = { value: e.id, label: e.nom };
    });
    setRepSelect(object);
    setRepsOptions(arrayOption);
  }

  async function getAnnees() {
    var arrayOption = [];
    var annee = await dispatch(fetchAnneeIms());
    var entities = annee.payload;
    arrayOption.push({ value: 0, label: "Année", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.annee });
    });
    setAnneeOptions(arrayOption);
  }

  async function getCycles() {
    var objCycle = {};
    var arrayOption = [];
    var cycle = await dispatch(fetchCycle());
    var entities = cycle.payload.cycles;
    arrayOption.push({ value: 0, label: "Cycle", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.cycles.id, label: e.cycles.designation });
      objCycle[e.id] = { value: e.cycles.id, label: e.cycles.designation };
    });
    setCycleSelect(objCycle);
    setCyclesOptions(arrayOption);
  }

  async function getBricks() {
    var object4 = {};
    var arrayOption = [];
    var lines = await dispatch(fetchBrick());
    var entities = lines.payload;
    arrayOption.push({ value: 0, label: "Brick", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      object4[e.id] = { value: e.id, label: e.nom };
    });
    setBrickSelect(object4);
    setBricksOptions(arrayOption);
  }

  async function getSpecialites() {
    var objSp = {};
    var arrayOption = [];
    var sp = await dispatch(fetchSpecialite());
    var entities = sp.payload;
    arrayOption.push({ value: 0, label: "Spécialité", isDisabled: true });
    entities.forEach((e) => {
      arrayOption.push({ value: e.id, label: e.nom });
      objSp[e.id] = { value: e.id, label: e.nom };
    });
    setSpecialitSelect(objSp);
    setSpecialiteOptions(arrayOption);
  }

  useEffect(() => {
    getReps();
    getBricks();
    getCycles();
    getAnnees();
    getContacts();
    getSpecialites();
  }, [location.id, dispatch]);

  function listData() {
    navigate.push("/ListOceData");
  }
  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container>
            <Row>
              <Col md="12">
                <Button
                  id="returnBtn"
                  className="btn-wd  mr-1 float-left"
                  type="button"
                  variant="info"
                  onClick={listData}
                >
                  <span id="returnSpan" className="btn-label">
                    <i className="fas fa-list"></i>
                  </span>
                  Retour à la liste
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Form action="" className="form" method="">
                  <Card>
                    <Card.Header>
                      <Card.Title as="h4">{"Ajouter données OCE"}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col className="pr-1" md="6">
                          <Form.Group>
                            <label>Fichier * </label>
                            <Form.Control
                              accept=".xls, .xlsx, .csv"
                              placeholder="Image"
                              type="file"
                              onChange={(value) => {
                                fileHandler(value);
                              }}
                            ></Form.Control>
                          </Form.Group>
                        </Col>

                        <Col className="pl-1" md="6">
                          <Form.Group id="moisClass">
                            <label> Année * </label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={anneeSelect}
                              onChange={(value) => {
                                setAnneeSelect(value);
                              }}
                              options={anneeOptions}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="pl-1" md="6">
                          <Form.Group id="moisClass">
                            <label> Mois * </label>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              value={mois}
                              onChange={(value) => {
                                setMois(value);
                              }}
                              options={optionsMois}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <br></br>
                      <div className="text-center">
                        <img
                          id="loaderTable"
                          className="hidden"
                          src={require("../../../assets/img/loader.gif")}
                          alt="loader"
                        />
                      </div>
                      <div
                        className="scroll-sync"
                        onScroll={(e) => {
                          document.querySelector(".table-scroll").scrollLeft =
                            e.target.scrollLeft;
                        }}
                      >
                        <div style={{ width: "2000px" }}></div>
                      </div>
                      <br></br>
                      <div className="table-responsive">
                        <table
                          className={`table table-striped table-scroll
                          }`}
                          style={{
                            display: entities.length > 0 ? "none" : "",
                          }}
                        >
                          <thead className="fixed-header">
                            <tr>
                              <th>CodeOneKeyEtablissement</th>
                              <th>Etablissement</th>
                              <th>Service</th>
                              <th>CodeOneKey</th>
                              <th>Contact</th>
                              <th>Specialite1</th>
                              <th>Statut</th>
                              <th>DatePlanifiee</th>
                              <th>DateVisite</th>
                              <th>DateEnvoi</th>
                              <th>CodeBrick</th>
                              <th>Canal</th>
                              <th>CodeVisite</th>
                              <th>Rep</th>
                              <th>Territoire</th>
                              <th>Panel BD.Cycle</th>
                              <th>Panel BD.Segment</th>
                              <th>Panel BD.Specialite1</th>
                              <th>Panel BD.Specialite2</th>
                              <th>Panel BD.Service</th>
                              <th>Panel BD.Brick</th>
                            </tr>
                          </thead>
                        </table>
                      </div>
                      <br></br>
                      <Button
                        className="btn-wd btn mr-1 float-right extraction"
                        id="blExt"
                        type="button"
                        variant="warning"
                        disabled={disabled}
                        style={{
                          display: entities.length === 0 ? "block" : "none",
                          float: "right",
                        }}
                        onClick={() => {
                          if (mois.value == null || anneeSelect.value == null) {
                            notify(2, "Veuillez choisir l'année et le mois");
                          } else {
                            showTable(
                              mois.value,
                              anneeSelect.label,
                              anneeSelect.value
                            );
                          }
                        }}
                      >
                        <span className="btn-label">
                          <i className="fas fa-cogs"></i>
                        </span>
                        Extraction
                      </Button>

                      <div className="table-responsive table-scroll">
                        <table
                          className="table table-striped"
                          style={{
                            display: entities.length === 0 ? "none" : "",
                          }}
                        >
                          <thead className="fixed-header">
                            <tr>
                              <th></th>
                              <th>CodeOneKeyEtablissement</th>
                              <th>Etablissement</th>
                              <th>Service</th>
                              <th>CodeOneKey</th>
                              <th>Contact</th>
                              <th>Specialite1</th>
                              <th>Statut</th>
                              <th>DatePlanifiee</th>
                              <th>DateVisite</th>
                              <th>DateEnvoi</th>
                              <th>CodeBrick</th>
                              <th>Canal</th>
                              <th>CodeVisite</th>
                              <th>Rep</th>
                              <th>Territoire</th>
                              <th>Panel BD.Cycle</th>
                              <th>Panel BD.Segment</th>
                              <th>Panel BD.Specialite1</th>
                              <th>Panel BD.Specialite2</th>
                              <th>Panel BD.Service</th>
                              <th>Panel BD.Brick</th>
                            </tr>
                          </thead>
                          <tbody>
                            {entities.map((val, key) => {
                              return (
                                <tr key={key}>
                                  <td>{val.index}</td>
                                  <td>{val.codeOnekeyEtab}</td>
                                  <td>{val.etablissement}</td>
                                  <td>{val.service}</td>
                                  <td>{val.codeOneKey}</td>
                                  <td
                                    className={
                                      val.testContact !== undefined
                                        ? "td-select"
                                        : ""
                                    }
                                  >
                                    {val.testContact === undefined ? (
                                      val.contact
                                    ) : (
                                      <div style={{ display: "grid" }}>
                                        <p className="parag-td">
                                          {val.contact}
                                        </p>
                                        <Select
                                          className="react-select primary"
                                          classNamePrefix="react-select"
                                          value={contactSelect[val.id_contact]}
                                          onChange={(value) => {
                                            var list = [...entities];
                                            list[key] = {
                                              ...list[key],
                                              id_contact: value.value,
                                            };
                                            setEntities(list);
                                            setContactSelect(value);
                                          }}
                                          options={contactOptions}
                                        />
                                      </div>
                                    )}
                                  </td>

                                  <td
                                    className={
                                      val.testSpecialite !== undefined
                                        ? "td-select"
                                        : ""
                                    }
                                  >
                                    {val.testSpecialite === undefined ? (
                                      val.specialite
                                    ) : (
                                      <div style={{ display: "grid" }}>
                                        <p className="parag-td">
                                          {val.specialite}
                                        </p>
                                        <Select
                                          className="react-select primary"
                                          classNamePrefix="react-select"
                                          value={
                                            specialitSelect[val.id_specialite]
                                          }
                                          onChange={(value) => {
                                            var list = [...entities];
                                            list[key] = {
                                              ...list[key],
                                              id_specialite: value.value,
                                            };
                                            setEntities(list);
                                            setSpecialitSelect(value);
                                          }}
                                          options={specialiteOptions}
                                        />
                                      </div>
                                    )}
                                  </td>

                                  <td>{val.statut}</td>
                                  <td>{val.datePlanifiee}</td>
                                  <td>{val.dateVisite}</td>
                                  <td>{val.dateEnvoi}</td>
                                  <td>{val.codeBrick}</td>
                                  <td>{val.canal}</td>
                                  <td>{val.codeVisite}</td>
                                  <td>
                                    {val.testRep === undefined ? (
                                      <>{val.rep}</>
                                    ) : (
                                      <div style={{ display: "grid" }}>
                                        <p className="parag-td">{val.rep}</p>
                                        <Select
                                          className="react-select primary"
                                          classNamePrefix="react-select"
                                          value={repSelect[val.id_rep]}
                                          onChange={(value) => {
                                            var list = [...entities];
                                            list[key] = {
                                              ...list[key],
                                              id_rep: value.value,
                                            };
                                            setEntities(list);
                                            setRepSelect(value);
                                          }}
                                          options={repsOptions}
                                        />
                                      </div>
                                    )}
                                  </td>
                                  <td>{val.territoire}</td>
                                  <td
                                    className={
                                      val.testCycle !== undefined
                                        ? "td-select"
                                        : ""
                                    }
                                  >
                                    {val.testCycle === undefined ? (
                                      val.cycle
                                    ) : (
                                      <div style={{ display: "grid" }}>
                                        <p className="parag-td">{val.cycle}</p>
                                        <Select
                                          className="react-select primary"
                                          classNamePrefix="react-select"
                                          value={cycleSelect[val.id_cycle]}
                                          onChange={(value) => {
                                            var list = [...entities];
                                            list[key] = {
                                              ...list[key],
                                              id_cycle: value.value,
                                            };
                                            setEntities(list);
                                            setCycleSelect(value);
                                          }}
                                          options={cyclesOptions}
                                        />
                                      </div>
                                    )}
                                  </td>

                                  <td>{val.panelSegment}</td>
                                  <td>{val.panelSpecialite1}</td>
                                  <td>{val.panelSpecialite2}</td>
                                  <td>{val.panelService}</td>
                                  <td
                                    className={
                                      val.testBrick !== undefined
                                        ? "td-select"
                                        : ""
                                    }
                                  >
                                    {val.testBrick === undefined ? (
                                      <>{val.brick}</>
                                    ) : (
                                      <div style={{ display: "grid" }}>
                                        <p className="parag-td">{val.brick}</p>
                                        <Select
                                          className="react-select primary"
                                          classNamePrefix="react-select"
                                          value={brickSelect[val.id_brick]}
                                          onChange={(value) => {
                                            var list = [...entities];
                                            list[key] = {
                                              ...list[key],
                                              id_brick: value.value,
                                            };
                                            setEntities(list);
                                            setBrickSelect(value);
                                          }}
                                          options={bricksOptions}
                                        />
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <br></br>
                      <hr></hr>
                      {!testBtn ? (
                        <Button
                          className="btn-fill pull-right"
                          type="button"
                          variant="info"
                          id="saveAdd"
                          onClick={() =>
                            saveTable(
                              entities,
                              uploadFile,
                              mois.value,
                              anneeSelect.value
                            )
                          }
                          style={{
                            display: entities.length === 0 ? "none" : "",
                          }}
                        >
                          Enregistrer
                        </Button>
                      ) : (
                        ""
                      )}
                    </Card.Body>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default InsertOceData;
