import { createAsyncThunk } from "@reduxjs/toolkit";
import Configuration from "../configuration";
var token = localStorage.getItem("x-access-token");


export const fetchImsData = createAsyncThunk("imsData/fetchImsData", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "imsData/allImsData", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
      body: JSON.stringify(action),
    });
    const imsData = await response.json();
    return imsData;
  });


  export const verifIms = createAsyncThunk("imsData/verifIms", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "imsData/verifIms", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
      body: JSON.stringify(action),
    });
    const imsData = await response.json();
    return imsData;
  });
  


  export const imsDataAdded = createAsyncThunk("imsData/addImsData", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "imsData/addImsData", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      body: JSON.stringify(action),
    });
    const imsData = await response.json();
    return imsData;
  });


export const saveFile = createAsyncThunk("imsData/saveFile", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "imsData/saveFile", {
      method: 'POST',
      headers: {
        'Accept': 'application/*',
        'x-access-token': token,
      },
      body: action
    });
    const imsData = await response.json();
    return imsData;
  });


  export const getImsData = createAsyncThunk("imsData/getImsData", async (id1) => {
    const id = id1;
    const response = await fetch(Configuration.BACK_BASEURL + "imsData/getImsData", {
      method: 'POST',
      headers: {
        'id': id,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': token
      },
  
    });
    const imsData = await response.json();
    return imsData;
  });

  
  
  export const imsDeleted = createAsyncThunk("imsData/delete", async (action) => {
    const response = await fetch(Configuration.BACK_BASEURL + "imsData/delete/"+action.id, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token':token
      },
    });
    const imsData = await response.json();
    return imsData;
  });
  

  
export const cheeckParams = createAsyncThunk("imsData/cheeckParams", async (action) => { 
  const response = await fetch(Configuration.BACK_BASEURL + "imsData/cheeckParams", {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token':token
    },
    body: JSON.stringify(action)

  });
  const imsData = await response.json();
  return imsData;
});

    