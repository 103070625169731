import { Button, Card, Container, Col } from "react-bootstrap";
import React, { useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getdata } from "../../../Redux/dataReduce";

// core components
function DetailsPltData() {
  const location = useParams();
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [data, setData] = React.useState([]);

  var id = location.id;

  const getData = useCallback(async () => {
    document.getElementById("loaderTable").classList.remove("hidden");
    var ims = await dispatch(getdata(id));
    var entities = ims.payload.data;
    setData(entities);
    document.getElementById("loaderTable").classList.add("hidden");
  }, [dispatch, id]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Container fluid>
        <ToastContainer />
        <div className="section-image">
          <Container className="container-details">
            <Card>
              <Card.Header className="background-white">
                <Col md="12">
                  <Button
                    id="returnBtn"
                    className="btn-wd  mr-1 float-left"
                    type="button"
                    variant="info"
                    onClick={(event) => {
                      navigate.goBack();
                    }}
                  >
                    <span id="returnSpan" className="btn-label">
                      <i className="fas fa-list"></i>
                    </span>
                    Retour à la liste
                  </Button>
                </Col>
                <br clear="all"></br>
                <Card.Title
                  className="background-white"
                  as="h3"
                  style={{ textAlign: "center" }}
                >
                  {"Détails PLT"}
                </Card.Title>
              </Card.Header>
              <Card>
                <div className="text-center">
                  <img
                    id="loaderTable"
                    className="hidden"
                    src={require("../../../assets/img/loader.gif")}
                    alt="loader"
                  />
                </div>
                <Card.Body>
                  <div
                    className="scroll-sync"
                    onScroll={(e) => {
                      document.querySelector(".table-scroll").scrollLeft =
                        e.target.scrollLeft;
                    }}
                  >
                    <div style={{ width: "2000px" }}></div>{" "}
                  </div>
                  <br></br>
                  <div className="table-scroll">
                    <table className={"table table-striped"}>
                      <thead className="fixed-header">
                        <tr>
                          <th>Line</th>
                          <th>Territoire</th>
                          <th>Matricule</th>
                          <th>Rep</th>
                          <th>Cycle</th>
                          <th>Semaine</th>
                          <th>Jour</th>
                          <th>TypeActivite</th>
                          <th>Contact</th>
                          <th>Spécialité</th>
                          <th>Brick</th>
                          <th>Commune</th>
                          <th>Segment</th>
                          <th>VilleBase</th>
                          <th>Fréquence</th>
                          <th>Distance</th>
                          <th>CodeOneKey</th>
                          <th>BaseDéplacement</th>
                          <th>TypePoste</th>s
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((val, key) => {
                          return (
                            <tr key={key}>
                              <>
                                <td>{val.lines ? val.lines.nom : ""}</td>
                                <td>{val.territoire}</td>
                                <td>{val.matricule}</td>
                                <td>{val.users ? val.users.nom : ""}</td>
                                <td>
                                  {val.cycles ? val.cycles.designation : ""}
                                </td>
                                <td>{val.semaine}</td>
                                <td>{val.jour}</td>
                                <td>{val.type_activite}</td>
                                <td>
                                  {val.personnels
                                    ? val.personnels.nomPrenom
                                    : ""}
                                </td>
                                <td>
                                  {val.specialites ? val.specialites.nom : ""}
                                </td>
                                <td>{val.bricks ? val.bricks.nom : ""}</td>
                                <td>{val.commune}</td>
                                <td>{val.segment}</td>
                                <td>{val.ville_base}</td>
                                <td>{val.frequence}</td>
                                <td>{val.distance}</td>
                                <td>{val.code_onekey}</td>
                                <td>{val.base_deplacement}</td>
                                <td>{val.type_poste}</td>
                              </>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </Card.Body>
              </Card>
            </Card>
          </Container>
        </div>
      </Container>
    </>
  );
}

export default DetailsPltData;
